//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "~/assets/loader-nancomcy.json";

export default {
  components: {
    Lottie,
  },

  data() {
    return {
      defaultOptions: { animationData: animationData.default, loop: false },
      anim: null,
      heightPreloader: 0,
    };
  },

  mounted() {
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
  },
  methods: {
    onAnimComplete() {
      this.heightPreloader = "100vh";
      document.body.style.height = "unset";
      document.body.style.overflowY = "unset";
      document.body.style.overflowX = "hidden";
    },
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.addEventListener("complete", this.onAnimComplete);
    },
  },
};
